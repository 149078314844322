<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <div class="card-title">
                    Route bearbeiten
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="card card-success card-outline" style="width: 100%">
                        <div class="card-header">
                            <div class="card-title">
                                Allgemeines
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="card-tools">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Bezeichnung*</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control form-control-sm" v-model="route.name" disabled>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrzeug</label>
                                            <div class="col-md-9">
                                                <select class="form-control form-control-sm" v-model="route.car_id" disabled>
                                                    <option :value="0">-- Filter Fahrzeuge --</option>
                                                    <option v-for="car in cars" :key="car.id" :value="car.id">{{car.name}}</option>
                                                </select>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Auslieferungstag*</label>
                                            <div class="col-md-9">
                                                <input type="date" class="form-control form-control-sm" v-model="route.deliveryDay" disabled>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrer*</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control form-control-sm" v-model="route.drivers_name" disabled>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <div class="card-title">
                                    Zwischenpunkte
                                </div>
                            </div>
                            <div class="card-body p-1" style="font-size: 13px;">
                                <table class="table table-hover">
                                    <tbody class="m-0">
                                        <draggable tag="ul" :list="route.route_elements" class="list-group" handle=".draggable">
                                            <li v-for="(element, index) in route.route_elements" :key="element.id" style="list-style: none;" class="m-1">
                                                <div class="row">
                                                    <div class="col-md-1 text-center">
                                                        
                                                    </div>
                                                    <div class="col-1">
                                                        {{element.position + 1}}
                                                    </div>
                                                    <div class="col-4">
                                                        <template v-if="element.own_addresses != null">
                                                            {{element.own_addresses.name}} (Benutzerdefiniert)
                                                        </template>
                                                        <template v-else-if="element.saved_addresses != null">
                                                            {{element.saved_addresses.name}} (Gespeichert)
                                                        </template>
                                                        <template v-else-if="element.dealers != null">
                                                            {{element.dealers.name}} (Händler)
                                                        </template>
                                                        <template v-else-if="element.wholesalers != null">
                                                            {{element.wholesalers.name}} (Großhändler)
                                                        </template>
                                                        <template v-else-if="element.producers != null">
                                                            {{element.producers.name}} (Lieferant)
                                                        </template>
                                                        <template v-else-if="element.stores != null">
                                                            {{element.stores.name}} (Filiale)
                                                        </template>
                                                        <template v-else-if="element.orders != null">
                                                            {{element.orders.shipping.first_name}} {{element.orders.shipping.last_name}} (Bestell Nr. {{element.orders.ordernumber}})
                                                        </template>
                                                    </div>
                                                    <div class="col-2 text-center">
                                                        <div v-if="selectedRow[index] && selectedCell === 'status'" class="input-group">
                                                            <select class="form-control form-control-sm" v-model="route.route_elements[index].status_id" @change="changeOrder">
                                                                <template v-for="state in states">
                                                                    <option v-if="state.name != 'rework' && state.name != 'on_the_way'" :key="state.id" :value="state.id">{{state.description}}</option>
                                                                </template>
                                                            </select>
                                                            <div class="input-group-append">
                                                                <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'status')"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                        <span @dblclick="handleEditCell(index, 'status')" v-else class="mr-1 badge" :class="setBadge(element)">
                                                            {{element.status.description}}
                                                        </span>
                                                    </div>
                                                    <div class="col-2 text-center">
                                                        <span class="badge badge-secondary">
                                                            <template v-if="element.type == 'pickup'">
                                                                Abholen
                                                            </template>
                                                            <template v-else>
                                                                Ausliefern
                                                            </template>
                                                        </span>
                                                    </div>
                                                    <div class="col-2">
                                                        <button class="btn btn-danger btn-xs pr-2 pl-2" v-b-popover.hover.top="'Fahrerkommentar'" @click="openDriversMessage(index)" v-if="element.drivers_message != null && element.drivers_message != ''"> 
                                                            <i class="fas fa-exclamation"></i> 
                                                        </button>
                                                    </div>
                                                </div>
                                                <br>
                                            </li>
                                        </draggable>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <template v-if="notEmptyObject(route)">
                            <GmapMap
                                :center="{lat:51.4157678, lng:7.9163869}"
                                :zoom="10"
                                map-type-id="terrain"
                                style="width: 100%; height: 100%"
                                :options="{
                                    streetViewControl: false,
                                }"
                                >
                                <template v-for="location in route.route_elements">
                                    <GmapMarker :zIndex="(location.position+1)*-1" :label="location.position+1+''" :position="google && new google.maps.LatLng(parseFloat(location.latitude), parseFloat(location.longitude))" :key="location.id"/>
                                </template>
                            </GmapMap>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <DriversMessageModal :route="route" :id="id" v-if="notEmptyObject(route)"/>
    </b-container>
</template>

<script>
import { gmapApi } from 'gmap-vue';
import draggable from 'vuedraggable'
import {mapState} from 'vuex';

import DriversMessageModal from './components/modals/driversMessage.vue'

export default {
    name: 'RouteDetails',
    title: 'Route bearbeiten',
    components: { draggable, DriversMessageModal}, 
    data() {
        return {
            route: [],
            cars: [],
            filter: null,
            id: null,
            selectedRow: {},
            selectedCell: null,
            states: [],
            selectedStopps: [],
        };
    },
    methods: {
        openDriversMessage(id)
        {
            this.id = id;
            this.$bvModal.show('driversMessage');
        },
        setBadge(element) {
            if(element.status.name == 'open')
            {
                return 'badge-danger';
            } else if(element.status.name == 'completed')
            {
                return 'badge-success';
            }
            
            return 'badge-primary';
        },

        loadRoute()
        {
            this.axios
                .get('/routes/' + this.$route.params.id)
                .then((response) => {
                    this.route = response.data.data;
                    this.route.route_elements.forEach(function(element, index) {
                        element.position = index;
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                });
        },
        loadCars()
        {
            this.axios
                .get('/cars')
                .then((response) => {
                    this.cars = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
    },
    mounted() {
        this.loadRoute();
        this.loadCars();
    },
    computed: {
        ...mapState('routes', ['params', 'search', 'page']),
        google: gmapApi,
    }
}
</script>
<style>
    .sortable-chosen {
        opacity: 0.2;
        border: solid black 3px;
    }
</style>